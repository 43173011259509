<!--  -->
<template>
<div class=''>
    <div class="text-home;text-align: center">
      <img style="zoom:0.06" src="../../../src/assets/img/logo.png" />
    </div>
    <h1 class="brand-info__text" style="line-height: 60px;text-align: center;">欢迎查看心狗健康报告</h1>
    <el-row style="margin-bottom: 20px;text-align: center;">
        <el-button type="success" @click="getExamReport()">体检报告</el-button>
        <el-button type="primary" @click="getECGReport()">心电报告</el-button>
        <el-button type="info" @click="logout()">退出登录</el-button>
    </el-row>
    <div v-if="showMessage" style="line-height: 100px;text-align: center;">您近七天内未检测心电。</div>
    <router-view v-else style="margin:10px"/>
</div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
//import引入的组件需要注入到对象中才能使用
components: {},
data() {
//这里存放数据
return {
    activeName: 'first',
    uid: "",
    sid:"",
    createTime: "",
    duration:"",
    showMessage: false
};
},
//监听属性 类似于data概念
computed: {},
//监控data中的数据变化
watch: {},
//方法集合
methods: {
    logout(){
        this.$message({
            message: "退出成功！",
            // message: data.msg,
            type: "success",
          });
        this.$router.replace({ name: "report_query" });
    },
    getECGReport(){
        if (this.sid == -1){
            this.showMessage = true
        }else {
            this.$router.push({
            name: 'HistorySingo2_query',
            query: {
                "uid":this.uid,
                "staticRecordId": this.sid,
                "staticRecordCreatetime": this.createTime,
                "staticRecordDuration": this.duration,
            }
            })
        }
        
    },
    getExamReport(){
        this.showMessage = false
        this.$router.push({
          name: 'ExamReport_query',
          query: {
            "uid":this.uid,
          }
        })
    }
},
//生命周期 - 创建完成（可以访问当前this实例）
created() {
    this.showMessage = false
    if (this.$route.query.uid != null && this.$route.query.uid != undefined) {
            this.crossPlatform = this.$route.query.crossPlatform
            this.uid = this.$route.query.uid
            this.sid = this.$route.query.staticRecordId
            this.createTime = this.$route.query.staticRecordCreatetime
            this.duration = this.$route.query.staticRecordDuration
        }
},
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() {

},
beforeCreate() {}, //生命周期 - 创建之前
beforeMount() {}, //生命周期 - 挂载之前
beforeUpdate() {}, //生命周期 - 更新之前
updated() {}, //生命周期 - 更新之后
beforeDestroy() {}, //生命周期 - 销毁之前
destroyed() {}, //生命周期 - 销毁完成
activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>

</style>